import trachDefPhoto from "./default/trach.jpg";
import hontarDefPhoto from "./default/hontar.jpg";
import janeckovaDefPhoto from "./default/janeckova.jpg";
import khotynenkoDefPhoto from "./default/khotynenko.jpg";
import volfovaDefPhoto from "./default/volfova.jpg";

import trachNormPhoto from "./x1/trach_x1.webp";
import hontarNormPhoto from "./x1/hontar_x1.webp";
import janeckovaNormPhoto from "./x1/janeckova_x1.webp";
import khotynenkoNormPhoto from "./x1/khotynenko_x1.webp";
import volfovaNormPhoto from "./x1/volfova_x1.webp";

import trachRetinaPhoto from "./x2/trach_x2.webp";
import hontarRetinaPhoto from "./x2/hontar_x2.webp";
import janeckovaRetinaPhoto from "./x2/janeckova_x2.webp";
import khotynenkoRetinaPhoto from "./x2/khotynenko_x2.webp";
import volfovaRetinaPhoto from "./x2/volfova_x2.webp";

const teamDefPhotos = [
  trachDefPhoto,
  hontarDefPhoto,
  janeckovaDefPhoto,
  khotynenkoDefPhoto,
  volfovaDefPhoto,
];

const teamNormPhotos = [
  trachNormPhoto,
  hontarNormPhoto,
  janeckovaNormPhoto,
  khotynenkoNormPhoto,
  volfovaNormPhoto,
];

const teamRetinaPhotos = [
  trachRetinaPhoto,
  hontarRetinaPhoto,
  janeckovaRetinaPhoto,
  khotynenkoRetinaPhoto,
  volfovaRetinaPhoto,
];

export { teamDefPhotos, teamNormPhotos, teamRetinaPhotos };
